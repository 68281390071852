import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";


const BASE_URL = "https://api.roboblog.co";

const initialState = {
  isLoading: false,
  blogData: [],
  priceList: [],
  getAllBlogs: [],
  userlist: [],
  manualBlogData: [] || {},
  imagelist: [],
  contentdata: {},
  subscriptionDetail: {},
  ReffData: [],
  floating_data: [],
  carousal_data: [],
  main_blog_data: [],
  check_blog_data: {},
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    SetLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    SetBlogData: (state, actions) => {
      state.blogData = actions.payload;
    },
    SetManualBlogData: (state, actions) => {
      state.manualBlogData = actions.payload;
    },
    SetPriceList: (state, actions) => {
      state.priceList = actions.payload;
    },
    SetAllBlogs: (state, actions) => {
      state.getAllBlogs = actions.payload;
    },
    adminuserdata: (state, actions) => {
      state.userlist = actions.payload;
    },
    banerdata: (state, actions) => {
      state.imagelist = actions.payload;
    },
    generated_content: (state, actions) => {
      state.contentdata = actions.payload;
    },
    Setcustomer: (state, actions) => {
      state.subscriptionDetail = actions.payload;
    },
    getreffdata: (state, actions) => {
      state.ReffData = actions.payload;
    },
    get_floating_data: (state, actions) => {
      state.floating_data = actions.payload;
    },
    get_carousal_data: (state, actions) => {
      state.carousal_data = actions.payload;
    },
    all_blogs_data: (state, actions) => {
      state.main_blog_data = actions.payload;
    },
    check_blog: (state, actions) => {
      state.check_blog_data = actions.payload;
    },
  },
});

export const { SetLoading, SetBlogData, SetPriceList, SetAllBlogs, adminuserdata, SetManualBlogData, banerdata, generated_content, Setcustomer, getreffdata, get_floating_data, get_carousal_data, all_blogs_data, check_blog } = globalSlice.actions;

export default globalSlice.reducer;

export function LoginUser({ signinData, setSigninData, navigate, dispatch }) {
  return async () => {
    const formdata = new FormData();
    formdata.append("email", signinData.email);
    formdata.append("password", signinData.password);
    const blog_post_id = localStorage.getItem("blog_post_id");

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/login`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("role", response?.data?.role);
        setSigninData({ email: "", password: "" });
        if (blog_post_id !== null) {
          navigate(`/${localStorage.getItem("resdomain")}`);
        } else if (response?.data?.role == "user") {
          navigate("/all-blogs");
        } else {
          navigate("/admin-dashboard");
        }
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // console.log(error?.status);
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

export function RegisterUser({ signupData, setSignupData, navigate, dispatch }) {
  return async () => {
    const formdata = new FormData();
    formdata.append("first_name", signupData.first_name);
    formdata.append("last_name", signupData.last_name);
    formdata.append("username", signupData.username);
    formdata.append("email", signupData.email);
    formdata.append("password", signupData.password);
    formdata.append("price_id", signupData.price_id);
    formdata.append("blog_id", signupData.blog_id);
    formdata.append("code", signupData.code);

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/register`, formdata);
      // console.log("response", response);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        window.location.href = response?.data?.session_url;
        dispatch(SetLoading(false));
        setSignupData({
          first_name: "",
          last_name: "",
          email: "",
          username: "",
          password: "",
        });
        navigate("/signin");
        return response?.data;
      } else {
        toast.error(response?.data?.message?.email?.[0]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message?.email?.[0]);
      toast.error(error?.response?.data?.message?.username?.[0]);
      toast.error(error?.response?.data?.message?.[0]);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// ------- enter email api ----------------

export function forgot({ dispatch, navigate, forgotData, setForgotData }) {
  return async () => {
    localStorage.setItem("email", forgotData?.email);
    const formdata = new FormData();
    formdata.append("email", forgotData.email);
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/sendotp`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        setForgotData({ email: "" });
        navigate("/reset-password");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

// ------- enter reset password api ----------------

export function resetpaasword({ input, navigate, dispatch }) {
  const email = localStorage.getItem("email");
  const formdata = new FormData();
  formdata.append("email", email);
  formdata.append("new_password", input.new_password);
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/forgot`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        localStorage.removeItem("email");
        navigate("/success");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.email[0]);
      dispatch(SetLoading(false));
    }
  };
}

export function create_blog({ payload, setBlogName, setBlogDescription, navigate, dispatch, setAdd, setCreate }) {
  return async () => {
    const token = localStorage.getItem("token")
    const { blogName, blogDescription } = payload;
    const formdata = new FormData();
    formdata.append("title", blogName);
    formdata.append("description", blogDescription);

    const requestOptions = {
      headers: token ? { Authorization: `Token ${token}` } : {},
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/stripe/create-blog`, formdata, requestOptions);
      if (response?.data) {
        if (response?.data?.message !== "Domain is already exits" && response?.data?.message !== "Domain is already exits") {
          dispatch(SetLoading(false));
          const blog = response?.data?.blogs[0];
          if (blog) {
            setAdd(false);
            setCreate(false);
            localStorage.setItem("blog_post_id", blog?.blog_id);
            localStorage.setItem("resdomain", blog?.subdomain_url);
            const domain = localStorage.getItem("resdomain");
            if (domain) {
              const sanitizedDomain = domain.trim();
              if (sanitizedDomain) {
                navigate(`/${sanitizedDomain}`);
                window.location.reload();
                dispatch(get_blog({ dispatch, blog_post_id: localStorage.getItem("blog_post_id") }))
              } else {
                console.error("Domain value is empty or invalid.");
              }
            } else {
              console.error("No domain found in localStorage.");
            }
          }
        } else {
          dispatch(SetLoading(false));
          localStorage.removeItem("blog_post_id");
          setBlogName("");
          setBlogDescription("");
          toast.error(response?.data?.message);
        }
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function create_url_blog({ url, setUrl, navigate, dispatch, setAdd, setCreate }) {
  return async () => {
    const token = localStorage.getItem("token")
    const formdata = new FormData();
    formdata.append("url", url);

    const requestOptions = {
      headers: token ? { Authorization: `Token ${token}` } : {},
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/stripe/blog-url`, formdata, requestOptions);
      if (response?.status === 200) {
        if (response?.data?.message !== "Domain is already exits" && response?.data?.message !== "Domain is not exits") {
          dispatch(SetLoading(false));
          setAdd(false);
          setCreate(false);
          const blog = response?.data?.message;
          if (blog) {
            localStorage.setItem("blog_post_id", blog.blog_id);
            localStorage.setItem("resdomain", blog?.business_info?.Subdomain);
            const domain = localStorage.getItem("resdomain");
            if (domain) {
              const sanitizedDomain = domain.trim();
              if (sanitizedDomain) {
                navigate(`/${sanitizedDomain}`);
                window.location.reload();
                dispatch(get_blog({ dispatch, blog_post_id: localStorage.getItem("blog_post_id") }))
              } else {
                console.error("Domain value is empty or invalid.");
              }
            } else {
              console.error("No domain found in localStorage.");
            }
          }
        }
        else {
          toast.error(response?.data?.message);
          setUrl("")
          localStorage.removeItem("blog_post_id");
          dispatch(SetLoading(false));
        }
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}
export function get_blog({ blog_post_id, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/stripe/get-blog?blog_id=${blog_post_id}`);
      // console.log("2222", response);
      if (response?.status) {
        dispatch(SetLoading(false));
        dispatch(SetBlogData(response?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}
export function edit_blog({ BlogData, payload, dispatch }) {
  return async () => {
    const formdata = new FormData();

    // Populate the FormData with payload or BlogData
    if (payload) {
      const { editableTitle, editableContent, id } = payload;
      formdata.append("title", editableTitle);
      formdata.append("content", editableContent);
      formdata.append("id", id);
    } else if (BlogData) {
      for (const key in BlogData) {
        formdata.append(key, BlogData[key]);
      }
    }

    try {
      dispatch(SetLoading(true));
      const idToUse = BlogData?.id || payload?.id;
      const response = await axios.put(`${BASE_URL}/api/stripe/blogposts/${idToUse}`, formdata);

      if (response?.status) {
        dispatch(SetLoading(false));
        const blog_post_id = localStorage.getItem("blog_post_id");
        dispatch(get_blog({ dispatch, blog_post_id }));
        toast.success("Blog post updated successfully!");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message || "Failed to update the blog post.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred.");
      dispatch(SetLoading(false));
    }
  };
}


export function getPriceList({ dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/stripe/list-prices`);
      if (response?.status) {
        dispatch(SetLoading(false));
        dispatch(SetPriceList(response?.data?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function getSuccessApi({ data, dispatch, navigate }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/stripe/success?user_id=${data}`);
      // console.log("2222", response);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        navigate("/signin");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
        navigate("/signup");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}
export function getCancelApi({ data, dispatch, navigate }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/stripe/cancle-subscription?user_id=${data}`);
      // console.log("2222", response);
      if (response?.status) {
        dispatch(SetLoading(false));
        navigate("/signup");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // dispatch(SetLoading(false));
    }
  };
}

export function getBlogSubscriptions(dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/stripe/blog-subscriptions`, requestOptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        localStorage?.setItem("carousal", response?.data?.data?.[0]?.domain)
        dispatch(SetAllBlogs(response?.data?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}

export function adminuserlist(dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/user-list`, requestOptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(adminuserdata(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}

export function UserActive(user, dispatch) {
  return async () => {
    const formdata = new FormData();
    const activeStatus = user?.active === true ? "False" : "True";
    formdata.append("active", activeStatus);
    formdata.append("user_id", user?.id);

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/active-user`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(adminuserlist(dispatch));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // console.log(error?.status);
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

export function get_mannual_blog({ mannual_blog_post_id, dispatch }) {
  return async () => {

    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/get-manual-blog-posts?manual_blog_post_id=${mannual_blog_post_id}`);
      if (response?.status) {
        dispatch(SetLoading(false));
        dispatch(SetManualBlogData(response?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function manualblogpost({ blogDetails, setBlogDetails, navigate, dispatch }) {
  return async () => {
    const formdata = new FormData();
    formdata.append("title", blogDetails?.title);
    formdata.append("domain", blogDetails?.domain);
    formdata.append("topic", blogDetails?.topic);
    formdata.append("keywords", blogDetails?.keywords);
    formdata.append("color_scheme", blogDetails?.colorScheme);
    formdata.append("description", blogDetails?.description);
    formdata.append("image_logo", blogDetails?.image_logo);
    formdata.append("image_logo_url", blogDetails?.image_logo_url);
    formdata.append("business_name", blogDetails?.advanced?.business_name);
    formdata.append("business_address", blogDetails?.advanced?.business_address);
    formdata.append("business_phone_number", blogDetails?.advanced?.business_phone_number);
    formdata.append("business_email_address", blogDetails?.advanced?.business_email_address);

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/manual-blog-posts`, formdata);
      if (response?.status === 201) {
        setBlogDetails({
          title: "",
          domain: "",
          topic: "",
          keywords: "",
          description: "",
          image_logo: null,
          heroBanner: "",
          introParagraph: "",
          image_logo_url: "",
          advanced: {
            business_name: "",
            business_address: "",
            business_phone_number: "",
            business_email_address: "",
          },
        })
        dispatch(SetLoading(false));
        localStorage.setItem("manual_blog_post_id", response?.data?.manual_blog_post_id)
        navigate("/manual-blogs")
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

export function heroposter({ data, dispatch }) {
  return async () => {
    const formdata = new FormData();
    formdata.append("keywords", data);

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/generate-hero-poster`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(banerdata(response?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // console.log(error?.status);
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}



export function edit_manual_blog({ payload, dispatch }) {
  return async () => {
    const { Title, Content, id } = payload;
    const formdata = new FormData();
    formdata.append("title", Title);
    formdata.append("description", Content);
    try {
      dispatch(SetLoading(true));
      const response = await axios.put(`${BASE_URL}/api/blog/${id}/edit`, formdata);
      if (response?.status) {
        dispatch(SetLoading(false));
        const blog_post_id = localStorage.getItem("manual_blog_post_id");
        dispatch(get_mannual_blog({ dispatch, mannual_blog_post_id: blog_post_id }));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}



export function generate_description({ gencontent, dispatch }) {
  return async () => {
    const formdata = new FormData();
    formdata.append("content", gencontent);
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/generate-content`, formdata);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(generated_content(response?.data?.generated_content));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}


export function activeplan(dispatch) {
  return async () => {
    const token = localStorage.getItem("token")
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.get(`${BASE_URL}/api/stripe/stripe-customer-subscription`, requestOptions);
      if (response?.status) {
        dispatch(SetLoading(false));
        dispatch(Setcustomer(response?.data?.message));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  }
}


export function generate_reff_code({ discount, setShow, dispatch }) {
  return async () => {
    const token = localStorage.getItem("token");

    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`
      }
    }
    try {
      const formdata = new FormData();
      formdata.append("discount", discount);

      const response = await axios.post(`${BASE_URL}/api/admin/create-activation-code`, formdata, requestOptions);

      if (response?.status === 200) {
        setShow(false);
        dispatch(SetLoading(false));
        dispatch(get_reff_code(dispatch))
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(SetLoading(false));
      toast.error(error?.response?.data?.error);
    }
  };
}



export function get_reff_code(dispatch) {
  return async () => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`
      }
    }
    try {

      const response = await axios.get(`${BASE_URL}/api/admin/get-activation-codes`, requestOptions);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(getreffdata(response?.data?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(SetLoading(false));
      toast.error(error?.response?.data?.error);
    }
  };
}



export function update_reff_code({ status, setShow, setDiscount, updateid, discount, dispatch }) {

  return async () => {
    const formdata = new FormData();
    const activeStatus = status?.status == "active" ? "inactive" : "active";
    formdata.append("status", activeStatus);
    formdata.append("discount", status?.discount || discount);
    formdata.append("id", status?.id || updateid);

    const token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`
      }
    }
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/admin/update-activation-code`, formdata, requestOptions);
      if (response?.status === 200) {
        dispatch(get_reff_code(dispatch));
        setDiscount("");
        setShow(false);
        dispatch(SetLoading(false));
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // console.log(error?.status);
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

export function floating({ dispatch, obj, setIsModalOpen }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/floating-button`, obj);
      if (response?.status === 201 || response?.status === 200) {
        setIsModalOpen(false)
        let blog_id = obj?.blog_id
        dispatch(get_floating({ dispatch, blog_id }));
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

export function carousal({ dispatch, obj }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/carousal`, obj);
      if (response?.status === 201 || response?.status === 200) {
        let blog_id = obj?.blog_id
        dispatch(get_carousal({ dispatch, blog_id }))
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch(SetLoading(false));
    }
  };
}

//-------------get-carousal

export function get_carousal({ dispatch, blog_id }) {
  return async () => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`
      }
    }
    try {

      const response = await axios.get(`${BASE_URL}/api/get-carousal?blog_id=${blog_id}`, requestOptions);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(get_carousal_data(response?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));

        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(SetLoading(false));
      toast.error(error?.response?.data?.error);
    }
  };
}

export function get_floating({ dispatch, blog_id }) {
  return async () => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`
      }
    }
    try {

      const response = await axios.get(`${BASE_URL}/api/get-floating-button?blog_id=${blog_id}`, requestOptions);
      if (response?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(get_floating_data(response?.data));
        return response?.data;
      } else {
        dispatch(SetLoading(false));

        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(SetLoading(false));
      toast.error(error?.response?.data?.error);
    }
  };
}


// export function get_all_blogs(dmn, navigate, dispatch) {
//   return async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/stripe/get-all-post?domain=${dmn}`);

//       if (response?.data?.status !== 200) {
//         dispatch(SetLoading(false));
//         toast.error(response?.data?.message || "An unexpected error occurred.");
//         window.location.href = "https://roboblog.co";
//         return;
//       }

//       const { message, data } = response?.data;

//       dispatch(SetLoading(false));
//       dispatch(all_blogs_data(data));
//       return data;

//     } catch (error) {
//       dispatch(SetLoading(false));
//       const errorMessage = error?.response?.data?.error || "An unexpected error occurred.";
//       toast.error(errorMessage);
//     }
//   };
// }


export const get_all_blogs = createAsyncThunk(
  "blogs/getAllBlogs",
  async (dmn, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/stripe/get-all-post?domain=${dmn}`
      );

      if (response?.data?.status !== 200) {
        dispatch(SetLoading(false));
        window.location.href = "https://roboblog.co";
        return rejectWithValue(response?.data?.message);
      }

      const { data } = response?.data;
      dispatch(all_blogs_data(data));
      dispatch(SetLoading(false));
      return data;
    } catch (error) {
      dispatch(SetLoading(false));
      return rejectWithValue(error?.response?.data?.error || "An error occurred.");
    }
  }
);



export const check_blogs = createAsyncThunk(
  "check_blogs",
  async (_, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${BASE_URL}/api/stripe/check_blogs`,
        requestOptions
      );

      if (response?.status === 200) {
        const blogData = response?.data;
        if (blogData) {
          dispatch(check_blog(blogData));
        }
        dispatch(SetLoading(false));
        return blogData;
      } else {
        throw new Error("Unexpected response status.");
      }
    } catch (error) {
      console.error("Error in check_blogs:", error);
      dispatch(SetLoading(false));
      return rejectWithValue(
        error?.response?.data?.error || error.message || "An error occurred."
      );
    }
  }
);



export function Add_manual_blog(formdata, dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(`${BASE_URL}/api/stripe/manual_blogs`, formdata, requestOptions);
      if (response?.status === 201) {
        dispatch(SetLoading(false))
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}




