import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const activeFiled = location?.pathname;

  const userLinks = [
    { pathname: "/all-blogs", title: "All Blogs Posts", icon: "fa-list" },
    { pathname: "/add-blog", title: "Automated AI Posting", icon: "fa-regular fa-comment-dots" },
    { pathname: "/add-manual-blog", title: "Add Manual Blog Post", icon: "fa-add" },
    { pathname: "/blog-website", title: "Add Blog to Your Website", icon: "fa-add" },
    { pathname: "/subscription-detail", title: "Subscription", icon: "fa-regular fa-money-bill-1" },
    // { pathname: "/sub-domain", title: "Domain", icon: "fa-regular fa-pen-to-square" }

  ];

  const adminLinks = [
    { pathname: "/admin-dashboard", title: "Dashboard", icon: "fa-dashboard" },
    { pathname: "/admin-reff-code", title: "Referral code", icon: "fa-regular fa-file" },
  ];



  const isAdmin = localStorage.getItem('role') === "admin";

  const links = isAdmin ? adminLinks : userLinks;

  return (
    <div className="side-bar">
      <a className="abs-bk">
        <i className="fa-solid fa-caret-left" />
      </a>
      <div className="logo-sec">
        {/* <img className="w-100 logo-top" src="#" alt=""> */}
        <h1 className="side-bar-heading">Site Blog</h1>
      </div>
      <div className="link-div">
        <ul className="link-ul">
          {links?.map((link, index) => (
            <li key={index}>
              <Link
                to={link?.pathname}
                className={`${activeFiled === link.pathname ? "active" : ""}`}
              >
                <i className={`fa-solid ${link?.icon}`} />
                {link?.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
