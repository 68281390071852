import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./VerticalCarousel.css";
import { carousal, get_carousal } from '../../redux/authslice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const VerticalCarousel = ({ posts, defaultHeading, initialFontSize, initialFontColor }) => {
    const dispatch = useDispatch();
    const carousal_data = useSelector((state) => state?.counter?.carousal_data)

    const [heading, setHeading] = useState(defaultHeading);
    const [fontSize, setFontSize] = useState(initialFontSize);
    const [fontColor, setFontColor] = useState(initialFontColor);

    console.log("carousal_data", carousal_data)
    useEffect(() => {
        if (carousal_data?.length > 0) {
            setHeading(carousal_data[0]?.heading || defaultHeading);
            setFontSize(carousal_data[0]?.heading_font_size || initialFontSize);
            setFontColor(carousal_data[0]?.heading_font_color || initialFontColor);
        }
    }, [carousal_data]);


    const obj = {
        "blog_id": localStorage?.getItem("blog_post_id"),
        "heading": heading,
        "heading_font_size": fontSize,
        "heading_font_color": fontColor
    }

    const Updatefont = () => {
        dispatch(carousal({ dispatch, obj }))
    }

    useEffect(() => {
        const blog_id = localStorage.getItem("blog_post_id")
        dispatch(get_carousal({ dispatch, blog_id }))
    }, [])





    const htmlCode = `
    <div class="slider">
        <div class="slides">
            <div class="slide" style="background-color: #3498db;">Slide 1</div>
            <div class="slide" style="background-color: #e74c3c;">Slide 2</div>
            <div class="slide" style="background-color: #2ecc71;">Slide 3</div>
        </div>
        <div class="nav-dots">
            <div class="dot active"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>

        <button class="prev" onclick="moveSlide(-1)">&#10094;</button>
        <button class="next" onclick="moveSlide(1)">&#10095;</button>
    </div>

    <script>
        let currentIndex = 0;

        function moveSlide(direction) {
            const slides = document.querySelectorAll('.slide');
            const dots = document.querySelectorAll('.dot');
            const totalSlides = slides.length;

            currentIndex = (currentIndex + direction + totalSlides) % totalSlides;

            document.querySelector('.slides').style.transform = \`translateX(-\${currentIndex * 100}%)\`;

            dots.forEach(dot => dot.classList.remove('active'));
            dots[currentIndex].classList.add('active');
        }

        setInterval(() => moveSlide(1), 3000);
    </script>
`;

    const cssCode = ` body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            background-color: #f0f0f0;
        }

        .slider {
            width: 80%;
            max-width: 600px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
        }

        .slides {
            display: flex;
            transition: transform 0.5s ease;
        }

        .slide {
            width: 100%;
            flex-shrink: 0;
            height: 300px;
            background-color: #ddd;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: white;
        }

        .nav-dots {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
        }

        .dot {
            height: 10px;
            width: 10px;
            margin: 0 5px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.5);
            transition: background-color 0.3s ease;
            cursor: pointer;
        }

        .dot.active {
            background-color: white;
        }

        .prev, .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #00000000;
            color: white;
            font-size: 24px;
            border: none;
            padding: 10px;
            cursor: pointer;
        }

        .prev {
            left: 10px;
        }

        .next {
            right: 10px;
        }
`

    return (
        <div className="container my-4 col-lg-8">
            <div className='d-flex justify-content-center'>
                <span className='text-dark h1'>Carousel section</span>
            </div>
            <div className='border border-2 rounded p-3'>
                <input
                    type="text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    style={{
                        fontSize: `${fontSize}px`,
                        color: fontColor,
                        fontWeight: 'bold',
                        border: 'none',
                        textAlign: 'center',
                        display: 'block',
                        width: '100%',
                        marginBottom: '10px'
                    }}
                    className="text-center"
                />

                <div className="d-flex justify-content-center mb-3">
                    <div className="me-3">
                        <label>Font Size: </label>
                        <input
                            type="number"
                            value={fontSize}
                            onChange={(e) => setFontSize(e.target.value)}
                            min="10"
                            max="50"
                            className="form-control"
                        />
                    </div>
                    <div>
                        <label>Font Color: </label>
                        <input
                            type="color"
                            value={fontColor}
                            onChange={(e) => setFontColor(e.target.value)}
                            className="form-control"
                        />

                    </div>
                    <div className="d-flex align-items-center justify-content-end top-0 position-relative">
                        <button onClick={Updatefont}>save</button>
                    </div>
                </div>

                <Carousel>
                    {posts?.map((post, index) => (
                        <Carousel.Item key={index}>
                            <div className='d-flex justify-content-center'>
                                <img
                                    className="w-50 img-fluid"
                                    src={post?.image_url ? post?.image_url : post?.business_info?.image_logo}
                                    alt={post?.title}
                                    style={{ maxHeight: '400px', objectFit: 'cover' }}
                                />
                            </div>
                            <div className="text-center my-3">
                                <h5 className='text-dark'>{post.title}</h5>
                                <p className="text-dark">
                                    {post.content?.match(/<\/?[a-z][\s\S]*>/i) ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: post.content?.slice(0, 200),
                                            }}
                                        />
                                    ) : (
                                        (post?.content ? post?.content?.slice(0, 200) : post?.description?.slice(0, 200)) + "..."
                                    )}
                                </p>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>


        </div>
    );
};



export default VerticalCarousel;
