import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_all_blogs } from "../redux/authslice";

const BlogPostList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const main_blog_data = useSelector((state) => state?.counter?.main_blog_data);

  useEffect(() => {
    const dmn = window.location.hostname;

    const fetchBlogs = async () => {
      try {
        await dispatch(get_all_blogs(dmn)).unwrap();
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();

    const handlePageShow = (event) => {
      if (event.persisted) {
        console.log("Page restored from bfcache, re-fetching blogs...");
        fetchBlogs();
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, [dispatch]);

  function cleanContent(content) {
    return content
      ?.replace(/\*\*/g, "")
      ?.replace(/\*/g, "")
      ?.replace(/:/g, "")
      ?.replace(/###/g, "\n\n\n")
      ?.replace(/title/gi, "")
      ?.split("\n")
      ?.map((line) => line.trim())
      ?.filter((line) => line.length > 0)
      ?.join("\n");
  }
  return (
    <div className="blog-posts m-5">
      {main_blog_data?.[0]?.posts
        ?.filter((ele) => ele.id == id)
        ?.map((blog) => (
          <div key={blog?.id} className="blog-post">
            <div className="d-flex justify-content-center">
              <img
                src={blog?.image_url}
                alt={blog?.title}
                style={{
                  width: "100%",
                  maxWidth: "70%",
                  marginBottom: "10px",
                  objectFit: "cover",
                  maxHeight: "300px",
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <h2 className="text-dark">{blog?.title}</h2>
            </div>
            <div className="d-flex justify-content-center p-5">
              <p
                className="font-jost blockquote"
                style={{ fontFamily: "jost" }}
              >
                {blog?.content?.match(/<\/?[a-z][\s\S]*>/i) ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: cleanContent(blog?.content),
                    }}
                  />
                ) : (
                  cleanContent(blog?.content)
                )}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default BlogPostList;
