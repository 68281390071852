import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get_all_blogs } from '../../redux/authslice';
import { toast } from 'react-toastify';

const BlogWebsite = () => {
    const dispatch = useDispatch();
    const main_blog_data = useSelector((state) => state?.counter?.main_blog_data)
    const cardContentRef = useRef(null);
    const [text, setText] = useState("Our Blog");
    const [bg_color, setbg_color] = useState("#007bff");
    const [text_color, settext_color] = useState("#ffffff");
    const [fontSize, setfontSize] = useState("16px");


    console.log("main_blog_data", main_blog_data)

    function cleanContent(content) {
        return content
            ?.replace(/\*\*/g, "")
            ?.replace(/\*/g, "")
            ?.replace(/:/g, "")
            ?.replace(/###/g, "\n\n\n")
            ?.replace(/title/gi, "")
            ?.split("\n")
            ?.map((line) => line.trim())
            ?.filter((line) => line.length > 0)
            ?.join("\n");
    }

    useEffect(() => {
        let dmn = localStorage.getItem("carousal");
        if (dmn) {
            dispatch(get_all_blogs(dmn, dispatch));
        }
    }, []);


    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code);
        toast.info("Code copied to clipboard!");
    };



    const handleScrollNext = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft +=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };

    const handleScrollPrev = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft -=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };


    const htmlCode = ` <div class="slider-main-page-main-blog" style="display: flex; align-items: center; overflow: hidden; width: 80%; max-width: 1200px; position: relative; margin: auto;">
    <button class="prev-main-page-main-blog" onclick="scrollPrev()" style="font-size: 24px; color: #333; border: none; border-radius: 50%; width: 40px; height: 40px; cursor: pointer; display: flex; justify-content: center; align-items: center; z-index: 1; background: none;">&#9664;</button>

    <div class="card-content-main-blog" id="cardContent" style="display: flex; gap: 20px; overflow-x: auto; scroll-behavior: smooth; width: 100%;">
        <div class="card-main-page-main-blog" style="flex: 0 0 auto; width: 300px; border: 1px solid #ddd; border-radius: 8px; overflow: hidden; background-color: #fff; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
            <a class="text-decoration-none" href="https://example.com/blog/1" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
                <div class="card-img-main-page-main-blog">
                    <img src="https://via.placeholder.com/300x180/3498db/ffffff?text=Blog+1" alt="Blog Post 1" style="width: 100%; height: 180px; object-fit: cover;">
                </div>
                <div class="card-text-main-page-main-blog" style="padding: 15px;">
                    <h2 style="font-size: 18px; margin: 0 0 10px;">Blog Post 1</h2>
                    <p style="font-size: 14px; color: #666;">Explore the first blog post and its amazing content...</p>
                </div>
            </a>
        </div>
        <div class="card-main-page-main-blog" style="flex: 0 0 auto; width: 300px; border: 1px solid #ddd; border-radius: 8px; overflow: hidden; background-color: #fff; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
            <a class="text-decoration-none" href="https://example.com/blog/2" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
                <div class="card-img-main-page-main-blog">
                    <img src="https://via.placeholder.com/300x180/e74c3c/ffffff?text=Blog+2" alt="Blog Post 2" style="width: 100%; height: 180px; object-fit: cover;">
                </div>
                <div class="card-text-main-page-main-blog" style="padding: 15px;">
                    <h2 style="font-size: 18px; margin: 0 0 10px;">Blog Post 2</h2>
                    <p style="font-size: 14px; color: #666;">Discover insights and stories in the second blog...</p>
                </div>
            </a>
        </div>
        <div class="card-main-page-main-blog" style="flex: 0 0 auto; width: 300px; border: 1px solid #ddd; border-radius: 8px; overflow: hidden; background-color: #fff; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
            <a class="text-decoration-none" href="https://example.com/blog/3" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
                <div class="card-img-main-page-main-blog">
                    <img src="https://via.placeholder.com/300x180/2ecc71/ffffff?text=Blog+3" alt="Blog Post 3" style="width: 100%; height: 180px; object-fit: cover;">
                </div>
                <div class="card-text-main-page-main-blog" style="padding: 15px;">
                    <h2 style="font-size: 18px; margin: 0 0 10px;">Blog Post 3</h2>
                    <p style="font-size: 14px; color: #666;">Learn more with the third blog post in this series...</p>
                </div>
            </a>
        </div>
    </div>

    <button class="next-main-page-main-blog" onclick="scrollNext()" style="font-size: 24px; color: #333; border: none; border-radius: 50%; width: 40px; height: 40px; cursor: pointer; display: flex; justify-content: center; align-items: center; z-index: 1; background: none;">&#9654;</button>
</div>

<script>
    const cardContent = document.getElementById("cardContent");

    function scrollPrev() {
        cardContent.scrollBy({
            left: -300,
            behavior: "smooth"
        });
    }

    function scrollNext() {
        cardContent.scrollBy({
            left: 300,
            behavior: "smooth"
        });
    }
</script>

`;





    const htmlCodebtn = `<div onclick="window.location.href='https://roboblog.co';" style="padding: 20px;">
    <div style="position: fixed; bottom: 35px; right: 20px; background-color: ${bg_color}; color: ${text_color}; padding: 10px 20px; border-radius: 25px; box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px; font-family: Arial, sans-serif; font-size: ${fontSize}; cursor: pointer; text-align: center; z-index: 1000;">
        ${text}
    </div>
</div>
`;

    return (
        <>
            <section className="dasboard-all pages mt-5">
                <div className="container-fluid">

                    <div className='d-flex flex-column justify-content-center text-center'>
                        <h3>Add Blog to Your Website</h3>
                        <span className='text-dark'>Slider for Your Website</span>
                    </div>
                    <div className="slider-main-page-main-blog">

                        <button className="prev-main-page-main-blog" onClick={handleScrollPrev}>
                            &#9664;
                        </button>

                        <div className="card-content-main-blog" ref={cardContentRef}>
                            {main_blog_data?.[0]?.posts?.map((ele, idx) => (
                                console.log("eeee", ele),
                                <div className="card-main-page-main-blog" key={idx} >
                                    <a
                                        className="text-decoration-none"
                                        href={`/blog/${ele?.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="card-img-main-page-main-blog">
                                            <img
                                                src={ele?.image_url}
                                                alt={ele?.title || "Default Alt Text"}
                                            />
                                        </div>
                                        <div className="card-text-main-page-main-blog">
                                            <h2>{ele?.title || "Default Title"}</h2>
                                            <p>
                                                {cleanContent(ele?.content?.slice(0, 100) || "Default description for the blog post.") + "..."}
                                            </p>

                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>

                        <button className="next-main-page-main-blog" onClick={handleScrollNext}>
                            &#9654;
                        </button>
                    </div>
                    <div style={copycode}><div
                        className="code-section"
                        style={{
                            marginTop: "40px",
                            padding: "20px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            maxWidth: "55%"
                        }}
                    >
                        <h3>HTML Code for Slider</h3>
                        <pre
                            style={{
                                backgroundColor: "#f4f4f4",
                                padding: "15px",
                                borderRadius: "5px",
                                overflow: "auto",
                                height: "200px"

                            }}
                        >
                            {htmlCode}
                        </pre>

                        <div >
                            <button
                                onClick={() => copyToClipboard(htmlCode)}
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                Copy HTML Code
                            </button>
                        </div>

                    </div></div>
                </div>
            </section>
            <section className="dasboard-all pages mt-5">
                <div className="container-fluid mt-5">

                    <div className='flt-btn-div' style={{ margin: " 110px 0px 0px 0px " }}>
                        <h3 className='d-flex justify-content-center'>Customize Button</h3>

                        <div className='d-flex flex-column justify-content-center'>
                            <div className='fom-div'>
                                <div className='mb-2'>
                                    <label className='px-3'>
                                        Button Text:
                                        <input
                                            type="text"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Background Color:
                                        <input
                                            type="color"
                                            value={bg_color}
                                            onChange={(e) => setbg_color(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Text Color:
                                        <input
                                            type="color"
                                            value={text_color}
                                            onChange={(e) => settext_color(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Font Size:
                                        <input
                                            type="text"
                                            value={fontSize}
                                            onChange={(e) => setfontSize(e.target.value)}
                                            placeholder="e.g., 16px"
                                        />
                                    </label>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <button className='d-flex justify-content-center rounded-pill'
                                        style={{
                                            backgroundColor: bg_color, color: text_color, fontSize: fontSize,

                                        }}
                                        disabled
                                    >
                                        {text}
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div style={copycode}><div
                            className="code-section"
                            style={{
                                marginTop: "40px",
                                padding: "20px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                width: "100%",
                                maxWidth: "55%"
                            }}
                        >
                            <h3>HTML Code for Floating Button</h3>
                            <pre
                                style={{
                                    backgroundColor: "#f4f4f4",
                                    padding: "15px",
                                    borderRadius: "5px",
                                    overflow: "auto",
                                }}
                            >
                                {htmlCodebtn}
                            </pre>

                            <div>
                                <button
                                    onClick={() => copyToClipboard(htmlCodebtn)}
                                    style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        border: "none",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        marginRight: "10px",
                                    }}
                                >
                                    Copy HTML Code
                                </button>
                            </div>

                        </div></div>
                    </div>
                </div>
            </section>

        </>
    )
}
const copycode = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
}


export default BlogWebsite