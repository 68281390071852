import './App.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RouteComponent from "./routes/Route"
import { useSelector } from 'react-redux';
import Loader from './Loader/Loader';


function App() {
  const { isLoading } = useSelector((state) => state.counter);

  return (
    <div className="App">
      {isLoading && <Loader />}
      <Router>
        <RouteComponent />
      </Router>
    </div>
  );
}

export default App;
