import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./sample.css";
import { useDispatch } from 'react-redux';
import { Add_manual_blog } from '../../redux/authslice';

const AddManualBlogPost = () => {
    const dispatch = useDispatch();
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');
    const [previewMode, setPreviewMode] = useState(false);
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({
        postTitle: '',
        postContent: '',
        image: '',
    });
    const fileInputRef = useRef(null);


    const validateFields = () => {
        let isValid = true;
        const newErrors = {
            postTitle: '',
            postContent: '',
            image: '',
        };

        if (!postTitle.trim()) {
            newErrors.postTitle = 'Post title is required.';
            isValid = false;
        }

        if (!postContent.trim()) {
            newErrors.postContent = 'Post content is required.';
            isValid = false;
        }

        if (!image) {
            newErrors.image = 'Image is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setErrors((prevErrors) => ({ ...prevErrors, image: '' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, image: 'Only image files are allowed.' }));
            }
        }
    };

    const handlePublish = () => {
        if (validateFields()) {

            const formdata = new FormData()
            formdata.append("title", postTitle);
            formdata.append("content", postContent);
            formdata.append("image", image);
            dispatch(Add_manual_blog(formdata, dispatch))
            setPostTitle('');
            setPostContent('');
            setImage(null);
            setPreviewMode(false);
            setErrors({ postTitle: '', postContent: '', image: '' });
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    return (
        <section className="dasboard-all pages">
            <div className="container-fluid">
                <div style={{ display: 'flex', height: 'auto', background: '#ececec' }}>
                    <div style={{ flex: 1, padding: '20px' }}>
                        <h3>Add New Post</h3>
                        {!previewMode ? (
                            <div>
                                {/* Post Title */}
                                <div style={{ marginBottom: '20px' }}>
                                    <label style={{ display: 'block', marginBottom: '5px' }}>Post Title</label>
                                    <input
                                        type="text"
                                        value={postTitle}
                                        onChange={(e) => {
                                            setPostTitle(e.target.value);
                                            if (e.target.value.trim()) {
                                                setErrors((prevErrors) => ({ ...prevErrors, postTitle: '' }));
                                            }
                                        }}
                                        placeholder="Enter post title"
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    {errors.postTitle && <p style={{ color: 'red', marginTop: '5px' }}>{errors.postTitle}</p>}
                                </div>

                                {/* Upload Image */}
                                <div style={{ marginBottom: '20px' }}>
                                    <label style={{ display: 'block', marginBottom: '5px' }}>Upload Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        ref={fileInputRef}
                                        style={{
                                            display: 'block',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    {image && (
                                        <div>
                                            <strong>Preview:</strong>
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt="Uploaded"
                                                style={{ width: '100%', maxWidth: '200px', marginTop: '10px' }}
                                            />
                                            <button
                                                onClick={() => setImage(null)}
                                                style={{
                                                    display: 'block',
                                                    padding: '5px 10px',
                                                    backgroundColor: '#d9534f',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    marginTop: '10px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                Remove Image
                                            </button>
                                        </div>
                                    )}
                                    {errors.image && <p style={{ color: 'red', marginTop: '5px' }}>{errors.image}</p>}
                                </div>

                                {/* Blog Post Content */}
                                <div style={{ marginBottom: '20px' }}>
                                    <label style={{ display: 'block', marginBottom: '5px' }}>Blog Post Content</label>
                                    <ReactQuill
                                        value={postContent}
                                        onChange={(value) => {
                                            setPostContent(value);
                                            if (value.trim()) {
                                                setErrors((prevErrors) => ({ ...prevErrors, postContent: '' }));
                                            }
                                        }}
                                        placeholder="Write your blog content here..."
                                        style={{
                                            marginBottom: '20px',
                                            background: '#ffffff',
                                        }}
                                    />
                                    {errors.postContent && <p style={{ color: 'red', marginTop: '5px' }}>{errors.postContent}</p>}
                                </div>

                                {/* Buttons */}
                                <div className="d-flex justify-content-end">
                                    <button
                                        onClick={() => setPreviewMode(true)}
                                        style={{
                                            padding: '0px 20px',
                                            backgroundColor: '#0054aa',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                            height: '33px',
                                        }}
                                    >
                                        Preview
                                    </button>
                                    <button
                                        onClick={handlePublish}
                                        style={{
                                            padding: '0px 20px',
                                            backgroundColor: '#0054aa',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            height: '33px',
                                        }}
                                    >
                                        Publish
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h3>Preview</h3>
                                <h4>{postTitle}</h4>
                                {image && (
                                    <div>
                                        <strong>Image Preview:</strong>
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt="Uploaded Preview"
                                            style={{ width: '100%', maxWidth: '200px', marginTop: '10px' }}
                                        />
                                    </div>
                                )}
                                <div
                                    className="m-3"
                                    dangerouslySetInnerHTML={{ __html: postContent }}
                                />
                                <button
                                    onClick={() => setPreviewMode(false)}
                                    style={{
                                        padding: '10px 20px',
                                        backgroundColor: '#337ab7',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '4px',
                                        marginTop: '20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AddManualBlogPost;
